import React from "react"

import { Link, useLocation } from "react-router-dom";

import './Footer.css';

function Footer(props) {

    const [nextLink, setNextLink] = React.useState("/cv");

    //let match = useRouteMatch("/cv")
    //console.log(match)

    let location = useLocation();
    React.useEffect(() => {
        switch (location.pathname) {
            case "/" : 
                setNextLink("/cv")
            break
            case "/cv" : 
                setNextLink("/case-study/mobile-music-making")
            break
            case "/case-study/mobile-music-making" : 
                setNextLink("/case-study/learning-synths")
            break
            case "/case-study/learning-synths" : 
                setNextLink("/personal-projects")
            break
            default :
                setNextLink("/")
        }
    }, [location]);

    return (
        <footer className="grid">

            <div className="contact col-start-1 col-end-6">
                <a className="email animated-underline" href="mailto:justin.rhoades@gmail.com">justin.rhoades@gmail.com</a>
                <a className="dribbble animated-underline" href="https://dribbble.com/jmrhoades">Dribbble</a>
                <a className="linkedin animated-underline" href="https://www.linkedin.com/in/justin-rhoades/">LinkedIn</a>
            </div>

            <div className="col-start-8 col-end-9">
                <Link className="next animated-underline" to={nextLink}><span>&mdash; Next</span></Link>
            </div>
            
        </footer>
    )
}
 
export default Footer;