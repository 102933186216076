import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Background from "./Background.js";
import Home from './Home.js';
import CV from './CV.js';
import CaseStudy from './CaseStudy.js';
import PersonalProjects from './PersonalProjects.js';
import Footer from './Footer.js';
import DesignChallenge from './DesignChallenge.js';

import './App.css';
import './Grid.css';

function App() {
  return (
    <BrowserRouter>
      <Background />
      <div className="App">
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/cv" component={CV} /> 
            <Route path="/case-study/:id" component={CaseStudy} />
            <Route path="/personal-projects" component={PersonalProjects} />
            <Route path="/design-challenge" component={DesignChallenge} />
            <Route component={Home} /> {/* Could be the NotFound component*/}
          </Switch>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
