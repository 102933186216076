import React, { useEffect } from "react"

import TopNav from './TopNav.js';
import Footer from './Footer.js';

import './CV.css';
import CVData from './cv-data.json'


function CV(props) {

  useEffect(() => {
    document.body.classList = "section-cv";
  });

  return (
    <div className="page cv">

      <div className="page-content">

      <div className="job-listings">
      {CVData.jobs.map((item, index)=>{
        
        let className = "job grid";
        if (item.assets) {
          className += " featured";
        }

        return(
          <div className={className} key={"job" + index}>
            
            <p className="when">{item.when}</p>
            
            <div className="meta">
              <span className="where">{item.where}</span> 
              <span className="divider">&nbsp;&mdash;&nbsp;</span> 
              <span className="role">{item.role}</span>
            </div>
            
            <p className="description">{item.description}</p>
            
            {item.info &&
              <p className="info">{item.info}</p>
            }

            {item.assets && item.assets.map((asset, assetIndex)=> {
              return (
              <figure className={asset.classes} key={assetIndex}>
                  {asset.source.substr(asset.source.length - 3) === "mp4" &&
                  <video width="100%" autoPlay={true} loop={true} muted={true} inline={""}>
                      <source src={asset.source} type="video/mp4" />
                  </video>
                  }
                  {asset.source.substr(asset.source.length - 3) === "png" &&
                      <img src={asset.source} alt="" width={asset.width} />
                  }
                  {asset.source.substr(asset.source.length - 3) === "jpg" &&
                      <img src={asset.source} alt="" />
                  }
                  {asset.caption &&
                      <figcaption>{asset.caption}</figcaption>
                  }
              </figure>
              )
            })}
          </div>
        )
      })}
      </div>
      
      {CVData.education.map((item, index)=>{
        return (
          <div className="edu grid" key={"edu" + index}>
            <p className="label">Education</p>
            <div className="meta">
              <span className="where">{item.where}</span>
              <span className="divider">&nbsp;&mdash;&nbsp;</span> 
              <span className="role">{item.location}</span>
            </div>
            <p className="description">{item.description}</p>
        </div>
        )
      })}
      
      {CVData.skills.map((item, index)=>{
        return (
          <div className="skills grid" key={"skills" + index}>
            <p className="label">Skills</p>
            <p className="description">{item.description}</p>
          </div>
        )
      })}
      
      <div className="recognition grid">
        <p className="label">Recognition</p>
        {CVData.recognition.map((item, index)=>{
          return <p className="description" key={"recog" + index}>{item.description}</p>
        })}
      </div>

      <div className="about grid">
        <p className="label">About</p>
        <p className="description">{CVData.about}</p>
      </div>

      <div className="download grid">
        <p className="label">Download</p>
        <p className="description">
          <a className="download_cv animated-underline" href="./justin_rhoades_cv_2019.pdf">justin.rhoades.cv.2019.pdf</a>
        </p>
      </div>

    </div>
    <TopNav pageTitle="CV &amp; Selected Work" nextLink="/case-study/mobile-music-making/"/>
  </div>
  )
}
 
export default CV;