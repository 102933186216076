import React, { useEffect } from "react"
import { Link } from 'react-router-dom';

import TopNav from './TopNav.js';
import Footer from './Footer.js';
import './Home.css';


function Home(props) {
    
    useEffect(() => {
        document.body.classList = "section-home";
    });

  return (
    <div className="home">
        <div className="home_top">
            <h1 className="home_name">Justin Rhoades</h1>
            <h1 className="home_title">Interface Design</h1>
        </div>
        <div className="home_nav">
            <h2><Link to="/cv" className="item">CV &amp; Selected Work</Link></h2>
            <h2><Link to="/case-study/mobile-music-making" className="item">Case study: Mobile Music Making</Link></h2>
            <h2><Link to="/case-study/learning-synths" className="item">Case study: Learning Synths</Link></h2>
            <h2><Link to="/personal-projects" className="item">Personal Projects</Link></h2>
        </div>
        <TopNav pageTitle="CV &amp; Selected Work" nextLink="/cv"/>
    </div>
  )
}
 
export default Home;