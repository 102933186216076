import React, { useEffect } from "react"

import TopNav from './TopNav.js';
import Footer from './Footer.js';

import './PersonalProjects.css';
import ProjectsData from './personal-projects-data.json'

function PersonalProjects(props) {

    useEffect(() => {
        document.body.classList = "section-personal-projects";
        window.scrollTo(0, 0)
    });

    return (
        <div className="page personal-projects">
            
            <div className="page-content">
                
                <div className="grid intro">
                    <p>
                        I like to make apps and web sites that involve video art and electronic music. These usually end up being excuses for developing fun and personally useful software that inevitably involves interesting interaction design problems. Here’s a selection of projects I've worked on.
                    </p>
                </div>
                
                { ProjectsData.projects.map((item, index)=> {
                    return (
                    <section className={"grid " + item.classes} key={item.id}>
                        <div className="when">{item.when}</div>
                        <div className="info">
                            <div className="title">&mdash;&nbsp;{item.title}</div>
                            <div className="description">{item.description}</div>
                        </div>
                        { item.assets.map((asset, assetIndex)=> {
                            return (
                            <figure className={asset.classes} key={assetIndex}>
                                {asset.source.substr(asset.source.length - 3) === "mp4" &&
                                <video width="100%" autoPlay={true} loop={true} muted={true} inline={""}>
                                    <source src={asset.source} type="video/mp4" />
                                </video>
                                }
                                {asset.source.substr(asset.source.length - 3) === "png" &&
                                    <img src={asset.source} alt="" width={asset.width} />
                                }
                                {asset.source.substr(asset.source.length - 3) === "jpg" &&
                                    <img src={asset.source} alt="" />
                                }
                                {asset.caption &&
                                    <figcaption>{asset.caption}</figcaption>
                                }
                            </figure>
                            )
                        })}
                        {item.links && 
                        <div className="links">
                        { item.links.map((link, linkIndex)=> {
                            return (
                            <p key={linkIndex}><a className="animated-underline" href={link.href}>{link.title}</a></p>
                            )
                        })}
                        </div>
                        }
                    </section>
                    )
                })}


            </div>
            <TopNav pageTitle="Personal Projects" nextLink={"/"}/>
        </div>
    )
}
 
export default PersonalProjects;