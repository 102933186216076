import React from "react";
import "./DesignChallenge.css";
import TopNav from "./TopNav.js";

function DesignChallenge(props) {
  React.useEffect(() => {
    document.body.classList = "section-design-challenge";
  });

  return (
    <div className="page design-challenge">
      <div className="page-content">
        <div className="grid">
          <h1 className="col-start-1 col-end-6">Design Challenge</h1>

          <div
            className="col-start-1 col-end-9"
            style={{ paddingBottom: "2em" }}
          >
            <svg viewBox="0 0 1029 200" className="illo">
              <circle strokeWidth="12" cx="55" cy="86" r="48"></circle>
              <line x1="89" y1="120" x2="139" y2="170" strokeWidth="12"></line>
              <path
                d="M895,40 L895,92 L947,92 L947,40 L895,40 Z"
                strokeWidth="12"
              ></path>
              <path
                d="M971,40 L971,92 L1023,92 L1023,40 L971,40 Z"
                strokeWidth="12"
              ></path>
              <path
                d="M895,116 L895,168 L947,168 L947,116 L895,116 Z"
                strokeWidth="12"
              ></path>
              <path
                d="M971,116 L971,168 L1023,168 L1023,116 L971,116 Z"
                strokeWidth="12"
              ></path>
              <polygon
                strokeWidth="12"
                points="443 56 553.504545 56 594 104 553.504545 152 443 152"
              ></polygon>
              <polygon
                className="fill"
                points="274.1 121 274.1 100.1 254.6 100.1 254.6 95.5 274.1 95.5 274.1 74.6 278.9 74.6 278.9 95.5 298.4 95.5 298.4 100.1 278.9 100.1 278.9 121"
              ></polygon>
              <polygon
                className="fill"
                points="743.1 121 743.1 100.1 723.6 100.1 723.6 95.5 743.1 95.5 743.1 74.6 747.9 74.6 747.9 95.5 767.4 95.5 767.4 100.1 747.9 100.1 747.9 121"
              ></polygon>
            </svg>
          </div>

          <div className="col-start-4 col-end-9" style={{ textAlign: "right" }}>
            Unify the desktop browsing and searching interfaces
          </div>

          <div className="divider" />

          <h4 className="col-start-1 col-end-5">Preamble / Disclaimer</h4>
          <p className="col-start-1 col-end-5">
            The following is a documentation of my design process during the
            time alotted for the challenge. The words and images are not
            representative of a recommended &lsquo;solution&rsquo; ready for
            production but are instead just the artifacts made along the way
            toward an understanding of the problem.
          </p>

          <div className="divider" />

          <h2 className="col-start-1 col-end-4">
            What Does Browsing Mean at Bandcamp?
          </h2>

          <p className="col-start-4 col-end-9 padding-top">
            My first step is to figure out what encompasses
            &lsquo;browsing&rsquo; on desktop Bandcamp. Aside from Search,
            there&rsquo;s also Tags and Discover. To get a better sense of how
            these features relate and compare, I take screen recordings of
            myself using them and then format them for this web page for
            reflection.
          </p>

          <div className="divider-small" />

          <h4 className="col-start-1 col-end-6">Search</h4>

          <p className="col-start-1 col-end-7">
            Using the term &lsquo;Drum &amp; Bass&rsquo;, I start off by
            clicking in the search field in the header. The placement of the
            search field is different depending on if you're logged in or not.
            Potentially confusing. Clicking in the search field shows me a
            colorful list of tags. Typing anything removes these and replaces it
            with an updating list of results. After entering the search term,
            I'm taken to the search results page. It&rsquo;s stark, contains a
            heterogenous list that I'm unable to sort and gives me little
            indication of how I can continue exploring aside from a tiny text
            link in the right column. Where&rsquo;d those colored genre links
            go?
          </p>

          <figure className="col-start-3 col-end-9">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-search.mp4"
                type="video/mp4"
              />
            </video>
          </figure>

          <h4 className="col-start-1 col-end-6">Discover</h4>

          <p className="col-start-1 col-end-7">
            What appears to be a useful, curated discovery feature inexplicably
            lives somewhere in the middle of the home page. To my eye, Discover
            has a promising interaction model and a pleasing interface that is
            unfortunately only skin-deep. Unpredicable behaviors abound,
            including finding this feature, but there's lots of potential. The
            background colors used for each genre do not correspond to the
            colors shown in the search drop down. This seems like a missed
            opportunity for strongly associating color with genre across the
            site.
          </p>

          <figure className="col-start-3 col-end-9">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-discover.mp4"
                type="video/mp4"
              />
            </video>
          </figure>

          <h4 className="col-start-1 col-end-6">Tags</h4>

          <p className="col-start-1 col-end-7">
            Based on my usage thus far, it seems that Discover is simply a nicer
            UI around Tags…plus some other metadata. After finding the Tags
            link, I’m met with a wall of blue links, no way to sort, no way to
            peak inside. It took me a while to realize that the top-level genre
            pages are also Tag pages. The sorting features, clear visual
            grouping and sometimes editorial content in the Tag pages should be
            incorporated into the unification effort.
          </p>

          <figure className="col-start-3 col-end-9">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-tags.mp4"
                type="video/mp4"
              />
            </video>
          </figure>

          <div className="divider" />

          <h2 className="col-start-1 col-end-4">
            How Do Other Products Solve These Problems?
          </h2>

          <p className="col-start-4 col-end-9 padding-top">
            Unifying these browsing concepts into a coherent and helpful UI must
            have analogues in other products. Before trying to invent anything
            new, I like to see how other companies are solving related problems.
            Paying close attention to platform vendors like Apple and Google and
            mass market players like Spotify and Soundcloud helps identify best
            practices while also sometimes serving as a catalyst for improving
            on the status quo.
          </p>

          <figcaption className="col-start-1 col-end-3 padding-top">
            Spotify does a good job structuring search results into clear
            categories as well as always providing one Top Result. Interesting
            to note that the search field is only accessible from the Search
            page, this means that category and genre links are displayed before
            any interaction with the search field can happen.
          </figcaption>
          <figure className="col-start-3 col-end-9 padding-top">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-spotify.mp4"
                type="video/mp4"
              />
            </video>
          </figure>

          <figure className="col-start-1 col-end-7 padding-top">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-soundcloud.mp4"
                type="video/mp4"
              />
            </video>
          </figure>
          <figcaption className="col-start-7 col-end-9 padding-top">
            Soundcloud’s search results presentaion is nowhere near as polished
            as Spotify’s, but it does offer some powerful ways of filtering
            results. This is probably perfect for Soundcloud’s audience in
            contrast to Spotify’s approach. My guess is that Bandcamp’s fans
            could make use of filtering features as well.
          </figcaption>

          <figcaption className="col-start-1 col-end-3 padding-top">
            And then there’s iTunes. Lots of visual noise, samey-looking artwork
            and de-emphasized headings make these search results hard to scan
            and impossible to filter. Nothing about this presentation strikes me
            as useful for Bandcamp.
          </figcaption>
          <figure className="col-start-3 col-end-9 padding-top">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-itunes.mp4"
                type="video/mp4"
              />
            </video>
          </figure>

          <div className="divider" />

          <h2 className="col-start-1 col-end-4">Interface Inventory</h2>

          <p className="col-start-4 col-end-9 padding-top">
            Now that I have a little grounding in the subject matter, it’s time
            to do an interface inventory of the current set of controls and
            layouts. Given that time is limited, I won’t attempt to invent a new
            design language for this challenge but instead try to take what’s
            there and massage it into something that speaks to the problem.
          </p>

          <div className="col-start-1 col-end-9">
            <img src="/assets/bandcamp/interface-inventory.jpg" alt="" />
          </div>

          <figcaption className="col-start-1 col-end-5">
            The incongruity between the colors in the search drop down and the
            colors in the Discover rows is immediately apparent. The players are
            another area of concern. Why is the Discover player different from
            the Tags player? Why doesn't Search have a player?
          </figcaption>

          <figcaption className="col-start-3 col-end-7">
            It appears that Search has access to all the content types whereas
            Discover only shows albums. Tags can show a mix of albums, artists,
            and fans…but not labels or tracks? It's hard to tell and it seems
            like those categories should be available when displaying results.
          </figcaption>

          <figcaption className="col-start-5 col-end-9">
            The Tag pages linked from the Search drop down (the top-level genres
            essentially) have a lot of nice editorial content. There’s something
            very welcome about the human voice being present in those pages that
            should be preserved and prioritzed in a unified Search/Discover
            page.
          </figcaption>

          <div className="divider" />

          <h2 className="col-start-1 col-end-4">Components</h2>

          <p className="col-start-1 col-end-6">
            With some basic requirements established and a unification approach
            somewhat understood, I start to work on a component that
            incorporates the filtering features of Discover into a familiar
            Search-like context.
          </p>

          <div className="col-start-1 col-end-7">
            <img src="/assets/bandcamp/requirements.svg" alt="" />
          </div>

          <div className="col-start-3 col-end-9 padding-top">
            <img src="/assets/bandcamp/discover-filters-1.jpg" alt="" />
          </div>

          <figcaption className="col-start-5 col-end-9">
            First attempt at combining the Search drop down colors with the
            Discover filters.
          </figcaption>

          <div className="col-start-3 col-end-9 padding-top">
            <img src="/assets/bandcamp/discover-filters-2.jpg" alt="" />
          </div>

          <figcaption className="col-start-5 col-end-9">
            Intending to clean things up, I briefly tried putting the filter
            options behind over-sized drop-down controls. This saves a lot of
            space but will probably lead to less usage.
          </figcaption>

          <div className="col-start-3 col-end-9 padding-top">
            <img src="/assets/bandcamp/discover-filters-3.jpg" alt="" />
          </div>

          <figcaption className="col-start-5 col-end-9">
            What if the search field was integrated into Discover?
          </figcaption>

          <div className="col-start-3 col-end-9 padding-top">
            <img src="/assets/bandcamp/discover-filters-4-b.jpg" alt="" />
          </div>

          <figcaption className="col-start-5 col-end-9">
            Primary context chooser: a custom drop-down integrated into the
            search bar. Removed sort-by in favor of a tag entry field.
          </figcaption>

          <div className="divider" />

          <h2 className="col-start-1 col-end-4">Mockup</h2>

          <p className="col-start-1 col-end-6">
            Now that I have an integrated Search & Discover component, it‘s time
            to add it to a hypothetical new Search page. This page replaces
            Discover and Search. Search, tags, filtering, editorial and fan
            content are all present. Generous white space prevents the layout
            from getting too busy and the floating player ensures that music is
            always part of the browsing experience.
          </p>

          <div className="col-start-1 col-end-7 padding-top">
            <img src="/assets/bandcamp/mockup-001.jpg" alt="" />
          </div>

          <figure className="col-start-3 col-end-9 padding-top">
            <video
              width="100%"
              autoPlay={true}
              loop={true}
              muted={true}
              inline={""}
            >
              <source
                src="/assets/bandcamp/screencap-mockup-1.mp4"
                type="video/mp4"
              />
            </video>
          </figure>

          <p className="col-start-1 col-end-6 info">
            Here‘s <a href="/assets/bandcamp/mockup.html">the mockup</a> if
            you’d like to scroll through it.
            <br />
            Thanks for your time and consideration.
            <br />
            —Justin
          </p>
        </div>
      </div>
      <TopNav pageTitle="" nextLink="/" />
    </div>
  );
}

export default DesignChallenge;
