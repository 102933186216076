import React from "react"
import { Link } from 'react-router-dom';

import './TopNav.css';


function TopNav(props) {


  return (
    <div className="topnav">
        <div className="grid">
            <Link  to="/" className="name col-start-1 col-end-3">Justin Rhoades</Link>
            <Link  to="/" className="title col-start-3 col-end-8">{props.pageTitle}</Link>
            <Link  to={props.nextLink} className="current col-start-8 col-end-9"><span>&mdash; Next</span></Link>
        </div>
    </div>
  )
}
 
export default TopNav;