import React from "react"
import "./Background.css"
 
function Background(props) {
  return (
    <div className="background">
      <img src="geometry-test-01.jpg" alt="" />
    </div>
  )
}
 
export default Background;